//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import MenuCard from "@/components/MenuCard";
export default {
  name: "Hospital",
  components: {
    MenuCard
  },
  computed: {
    ...mapState({
      isHospitalAccountExist: state => state.substrate.isHospitalAccountExist
    })
  }
};